import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer"; // Import Intersection Observer
import "./desktopfeature.css";
import { useMediaQuery } from "react-responsive";
import { Icon } from "@iconify/react";
import icon1 from "../../assets/features-icon1.png";

const DesktopFeature = () => {
  const mobile = useMediaQuery({ query: "(max-width: 640px)" });

  // Intersection Observer for animation trigger
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.2, // Trigger when 20% of the section is visible
  });

  const featureIcons = [
    "mdi:account-cash-outline",
    "mdi:cog-sync",
    "mdi:chart-line",
    "mdi:check-decagram",
  ];


  // Variants for animations
  const fadeInUp = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref} className="df-did-you-know-heading">
      {/* <motion.h1
        className="did_you_know fs-xl-heading"
        style={{
          textAlign: "center",
          fontFamily: "poppins",
          margin: 0,
          padding: "60px 0 1px 0",
          backgroundColor: "rgba(41, 197, 234, 0.05)",
          textEmphasisColor: "00061C",
          fontWeight: "600",
        }}
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Trigger animation when in view
        variants={fadeInUp}
        transition={{ duration: 1 }}
      >
        Why choose <span style={{fontFamily:'audiowide'}}>TERA</span>weld<span style={{ color: "orange" }}>?</span>
      </motion.h1> */}
      <div className="df-terafac-live df-feature-column d-flex flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row">
        <motion.div
          className="df-feature-first-column col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5"
          initial="hidden"
          animate={inView ? "visible" : "hidden"} // Trigger animation when in view
          variants={fadeInUp}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <div className="df-first-pic-div">
            <img
              className="df-feature-first-pic"
              src={require("../../assets/abb-robot.jpeg")}
              alt=""
              draggable={false}
            />
          </div>
          <div className="df-second-pic-div">
            <motion.img
              className="df-feature-second-pic"
              src={require("../../assets/pnc.jpeg")}
              alt=""
              draggable={false}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 0.4 }}
            />
            <motion.h1
              className="fs-xl-heading"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 0.6 }}
              style={{ fontFamily: 'Audiowide', fontWeight: 400 }}
            >
              TERAFAC <span style={{ fontWeight: 400 }}></span>
            </motion.h1>
            <motion.img
              className="df-feature-third-pic"
              src={require("../../assets/operator.jpeg")}
              alt=""
              draggable={false}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 0.8 }}
            />
          </div>
        </motion.div>
        <motion.div
          className="df-feature-second-column col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7"
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 1, delay: 1 }}
        >
          {[
            "Low initial investment, ROI within 2 years.",
            "Easy setup, operator-friendly interface, no expert required.",
            "3x productivity, reduce wastage, improve quality",
            "Centralized data for better decision-making."
          ].map((text, index) => (
            <motion.div
              key={index}
              className={`col-9 df-features-div ${index % 2 === 1 ? "offset-2" : ""}`}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 1 + index * 0.2 }}
            >
              <div className="df-icon-container">
                <Icon
                  icon={featureIcons[index]}
                  size={"30px"} // Adjusted size
                  style={{ fontSize: mobile ? "18px" : "30px", color: "white" }} // Adjusted fontSize
                />
              </div>
              <h2 className="fs-xl-para">{text}</h2>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default DesktopFeature;
