import "./hero.css";
import { useMediaQuery } from "react-responsive";
import background from "../../assets/background_terafac.jpg";

const Hero = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const isSmallScreen = useMediaQuery({
    query: "(min-width: 577px) and (max-width: 767px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px) and (max-width: 1279px)",
  });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 1280px)",
  });


  return (
    <section
      id="hero-top"
      className="hero-top d-flex flex-column justify-content-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-8'>
              <h1 className='identify-text'>
                <sup className='identify'>
                  Redefining
                </sup>
                {
                  isMobile && (
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>
                      {`${isMobile ? ' ' : ''}manufacturing${isMobile ? '\n' : ' '}through AI ${isMobile ? '\n' : ' '} & Vision`}
                    </span>
                  )
                }

                {
                  (isSmallScreen || isTablet || isLaptop || isLargeScreen) && (
                    <span style={{ fontFamily: 'Poppins, sans-serif' }}>
                      {`manufacturing\nthrough AI & Vision`}
                    </span>
                  )
                }

                
              </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;