import React from "react";
import "./videosection.css";
import YoutubePlayer from "../Youtube/YoutubePlayer";

const VideoSection = () => {
  return (
    <div id="demo" className="demo video-section">
      <div className="container">
        <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="col-xl-7 col-lg-7 icon-boxes d-flex flex-column line-height ">
            <p className="align-self-lg-start font-family-description">
              <span style={{ fontFamily: "poppins" }} className="video-heading-span">Introducing</span>
              <span style={{ fontFamily: "Audiowide" }} className="video-heading-span"> WELD-T</span>{" "}
              
            </p>
            <p style={{}} className="video-sub-heading"> an AI-Vision platform to make welding smart </p>

            {/* Using default bullets with <ul> */}
            <ul className="list-group" style={{ fontFamily: "Nunito" }}>
              <li className="list-item">Automatically scans the orientation and deviations of item.</li>
              <li className="list-item">Fine tunes welding parameters.</li>
              <li className="list-item">Works with off-the-shelf robots and welding power sources.</li>
              <li className="list-item">Eliminates dependency on programmers.</li>
            </ul>

          </div>
          <div className="video-inner-div col-xl-5 col-lg-5 d-flex align-items-center justify-content-center">
            <div className="col-xl-4 col-lg-6 video-box d-flex justify-content-center align-items-center">
              <YoutubePlayer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;