// import React, { useEffect, useState } from 'react'
// import './whoweare.css'
// import EmailPopup from '../EmailPopup/EmailPopup'
// import { useDispatch, useSelector } from 'react-redux'
// import { postInformationToDiscord } from '../../action/HomePage.action'

// const WhoWeAre = () => {
//   const [showEmailPopup, setShowEmailPopup] = useState(false)
//   const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' })

//   const handleEmailPopupClose = () => {
//     setShowEmailPopup(false)
//   }

//   const savedEmail = useSelector(state => state.home.email)

//   const dispatch = useDispatch()

//   function encodeEmailToBase64 (email) {
//     console.log('Experience encodeEmailToBase64 email', email)
//     const encodedEmail = btoa(email)
//     return encodedEmail
//   }

//   const handleEmailPopupOpen = event => {

//     console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

//     const encodedEmail = encodeEmailToBase64(savedEmail)
//     const buttonText = event.target.innerText
//     const buttonName = event.target.getAttribute('name')
//     const href = event.target.getAttribute('href')

//     if (buttonName == 'Enter JEX') {
//       if (savedEmail == '') {
//         event.preventDefault()
//         const baseUrl = event.currentTarget.getAttribute('href')
//         const fullUrl = `${baseUrl}`
//         window.location.href = fullUrl
//       } else {
//         dispatch(postInformationToDiscord(savedEmail))
//         event.preventDefault()
//         const baseUrl = event.currentTarget.getAttribute('href')
//         const fullUrl = `${baseUrl}?email=${encodedEmail}`
//         window.location.href = fullUrl
//       }
//     } else if (buttonName == 'Enter Discord') {
//       if (savedEmail == '') {
//         // alert(href)
//         event.preventDefault()
//         setPopupContent({ href, buttonText })
//         setShowEmailPopup(true)
//       } else {
//         dispatch(postInformationToDiscord(savedEmail))
//         event.preventDefault()
//         const baseUrl = event.currentTarget.getAttribute('href')
//         const fullUrl = `${baseUrl}`
//         window.location.href = fullUrl
//       }
//     } else {
//       if (savedEmail == '') {
//         // alert(href)
//         event.preventDefault()
//         setPopupContent({ href, buttonText })
//         setShowEmailPopup(true)
//       } else {
//         dispatch(postInformationToDiscord(savedEmail))
//         event.preventDefault()
//         const baseUrl = event.currentTarget.getAttribute('href')
//         const fullUrl = `${baseUrl}?email=${encodedEmail}`
//         window.location.href = fullUrl
//       }
//     }
//   }

//   return (
//     <div id='whoweare' className='whoweare'>
//       <div className='whoweare-main d-flex flex-column container'>
//         <div className='company-features d-flex align-items-center justify-content-center'>
//           <div className='company-feature d-flex align-items-center justify-content-center'>
//             <div className='feature-icon-box'>
//               <div className='feature-title-box'>
//                 <div className='img-div'>
//                   <img
//                     className='whoweare-icon'
//                     src={require('../../assets/whoweare-icon4.png')}
//                     alt=''
//                     draggable="false"
//                   />
//                 </div>
//                 <h4 className='text-center fs-xl-para'>
//                 Point and Click Software
//                 </h4>
//               </div>
//               <p className='text-center fs-sm-para'>
//               Automatically generates welding program based on operator input.
//               </p>
//             </div>
//           </div>

//           <div className='company-feature d-flex align-items-center justify-content-center'>
//             <div className='feature-icon-box'>
//               <div className='feature-title-box'>
//                 <div className='img-div'>
//                   <img
//                     className='whoweare-icon'
//                     src={require('../../assets/whoweare-icon5.png')}
//                     alt=''
//                     draggable="false"
//                   />
//                 </div>
//                 <h4 className='col-12 text-center fs-xl-para'>
//                 AI-Vision for robots
//                 </h4>
//               </div>
//               <p className='text-center fs-sm-para'>
//               Detects orientation and weld seams to automatically adapt the program for deviations.
//               </p>
//             </div>
//           </div>

//           <div className='company-feature d-flex align-items-center justify-content-center'>
//             <div className='feature-icon-box'>
//               <div className='feature-title-box'>
//                 <div className='img-div'>
//                   <img
//                     className='whoweare-icon'
//                     src={require('../../assets/whoweare-icon6.png')}
//                     alt=''
//                     draggable="false"
//                   />
//                 </div>
//                 <h4 className='text-center fs-xl-para'>
//                 AI welder
//                 </h4>
//               </div>
//               <p className='text-center fs-sm-para'>
//               Fine tunes weld parameters automatically
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       {showEmailPopup && (
//         <EmailPopup
//           buttonName={popupContent.buttonText}
//           hrefUrl={popupContent.href}
//           onClose={handleEmailPopupClose}
//         />
//       )}
//     </div>
//   )
// }

// export default WhoWeAre

import React, { useEffect, useState } from 'react';
import './whoweare.css';
import EmailPopup from '../EmailPopup/EmailPopup'
import { useDispatch, useSelector } from 'react-redux';
import { postInformationToDiscord } from '../../action/HomePage.action';

// Note: This is a simplified version for demo purposes 
// In a real implementation, you would use your actual EmailPopup component and Redux functionality

const WhoWeAre = () => {
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({ href: '', buttonText: '' });

  const handleEmailPopupClose = () => {
    setShowEmailPopup(false);
  };

  const savedEmail = useSelector(state => state.home.email)

  const dispatch = useDispatch()

  function encodeEmailToBase64 (email) {
    console.log('Experience encodeEmailToBase64 email', email)
    const encodedEmail = btoa(email)
    return encodedEmail
  }

  const handleEmailPopupOpen = event => {

    console.log('Navbar handleEmailPopupOpen savedEmail', savedEmail)

    const encodedEmail = encodeEmailToBase64(savedEmail)
    const buttonText = event.target.innerText
    const buttonName = event.target.getAttribute('name')
    const href = event.target.getAttribute('href')

    if (buttonName == 'Enter JEX') {
      if (savedEmail == '') {
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    } else if (buttonName == 'Enter Discord') {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}`
        window.location.href = fullUrl
      }
    } else {
      if (savedEmail == '') {
        // alert(href)
        event.preventDefault()
        setPopupContent({ href, buttonText })
        setShowEmailPopup(true)
      } else {
        dispatch(postInformationToDiscord(savedEmail))
        event.preventDefault()
        const baseUrl = event.currentTarget.getAttribute('href')
        const fullUrl = `${baseUrl}?email=${encodedEmail}`
        window.location.href = fullUrl
      }
    }
  }

  return (
    <div id='whoweare' className='whoweare'>
      <div className='whoweare-main d-flex flex-column container'>
        <div className='company-features'>
          <div className='company-feature'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare_pic004.png')}
                    alt=''
                    draggable="false"
                  />
                </div>
                <h4 className='text-center fs-xl-para'>
                  Point and Click Software
                </h4>
              </div>
              <p className='text-center fs-sm-para ' style={{ fontFamily: "Nunito" }}>
              Automatically generates welding program based on operator input.
              </p>
            </div>
          </div>

          <div className='company-feature'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare_pic005.png')}
                    alt=''
                    draggable="false"
                  />
                </div>
                <h4 className='text-center fs-xl-para'>
                  AI-Vision for robots
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
                Detects orientation and weld seams to automatically adapt the program for deviations.
              </p>
            </div>
          </div>

          <div className='company-feature'>
            <div className='feature-icon-box'>
              <div className='feature-title-box'>
                <div className='img-div'>
                  <img
                    className='whoweare-icon'
                    src={require('../../assets/whoweare_pic006.png')}
                    alt=''
                    draggable="false"
                  />
                </div>
                <h4 className='text-center fs-xl-para'>
                  AI welder
                </h4>
              </div>
              <p className='text-center fs-sm-para'>
                Fine tunes weld parameters automatically
              </p>
            </div>
          </div>
        </div>
      </div>
      {showEmailPopup && (
        <EmailPopup
          buttonName={popupContent.buttonText}
          hrefUrl={popupContent.href}
          onClose={handleEmailPopupClose}
        />
      )}
    </div>
  );
};

export default WhoWeAre;
