import React, { useState } from "react";
import HomeContact from "./HomeContact";

const ContactPage = () => {
  return (
    <div className="contact-main-div">
      <HomeContact />
    </div>
  );
};

export default ContactPage;
