import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./tabletfeature.css";
import { Icon } from "@iconify/react";
import { useMediaQuery } from "react-responsive";

const TabletFeature = () => {
  const mobile = useMediaQuery({ query: "(max-width: 640px)" });

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const features = [
    "Low initial investment, ROI within 2 years.",
    "Easy setup, operator-friendly interface, no expert required.",
    "3x productivity, reduce wastage, improve quality.",
    "Centralized data for better decision-making.",
  ];

  const featureIcons = [
    "mdi:account-cash-outline",
    "mdi:cog-sync",
    "mdi:chart-line",
    "mdi:check-decagram",
  ];

  return (
    <div ref={ref} className="tf-feature-column d-flex flex-row">
      <motion.div
        className="tf-feature-first-column col-4"
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 1, delay: 0.2 }}
      >
        <div className="tf-first-pic-div">
          <img
            className="tf-feature-first-pic"
            src={require("../../assets/abb-robot.jpeg")}
            alt=""
          />
        </div>

        <div className="tf-second-pic-div">
          <img
            className="tf-feature-second-pic"
            src={require("../../assets/pnc.jpeg")}
            alt=""
          />
          <h1 style={{ fontFamily: 'Audiowide', fontWeight: 400 }} className="tf-terafac-live">TERAFAC</h1>
          <img
            className="tf-feature-third-pic"
            src={require("../../assets/operator.jpeg")}
            alt=""
          />
        </div>
      </motion.div>
      <div className="tf-feature-second-column col-8">
        {features.map((text, index) => (
          <motion.div
            key={index}
            className={`col-9 tf-features-div ${
              index % 2 === 1 ? "offset-2" : ""
            }`}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeInUp}
            transition={{ duration: 1, delay: index * 0.2 }}
          >
            <div className="tf-icon-container">
              <Icon
                icon={featureIcons[index]} // Use the icon from the array
                style={{
                  fontSize: "35px",
                  width: "30px",
                  height: "30px",
                  color: "white",
                }}
              />
            </div>
            <h2  style={{marginLeft:10, marginRight:10}}>{text}</h2>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default TabletFeature;
