import React, { useState } from 'react';
import './modal.css'
import { FaCentercode } from 'react-icons/fa';

function CustomModal({ modalTitle, closeButtonName, actionButtonName, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        modalTitle: modalTitle
    });
    
    const [showPopup, setShowPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent default form submission
        await postSummaryToDiscord(formData);  // Post data to Discord
        onSubmit(formData);  // Calls the function in Navbar.js to close modal & show popup
    };

    return (
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title ms-auto" id="staticBackdropLabel">{modalTitle}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className="col">
                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                    <input type="text" className="form-control" name="firstName" value={formData.firstName} onChange={handleChange} />
                                </div>
                                <div className="col">
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone Number</label>
                                <input type="tel" className="form-control" name="phone" value={formData.phone} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label">Message</label>
                                <textarea className="form-control" name="message" rows="3" value={formData.message} onChange={handleChange}></textarea>
                            </div>
                            <div className="new-modal-footer">
                            {/* <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                style={{
                                    backgroundColor: '#3b3b3b',   
                                    borderRadius: '10px',    
                                    color: 'white',                
                                    fontWeight: 300,               
                                    padding: '5px 20px',          
                                    fontSize: '18px',              
                                    transition: 'background-color 0.3s, border-color 0.3s', 
                                }}
                                onMouseEnter={(e) => {
                                    // On hover, change the background and border color
                                    e.target.style.backgroundColor = '#29c5ea';
                                    e.target.style.borderColor = '#29c5ea';
                                    e.target.style.outline = 'none';
                                    e.target.style.border = '2px solid transparent';
                                }}
                                onMouseLeave={(e) => {
                                    // On mouse leave, revert the background and border color
                                    e.target.style.backgroundColor = '#3b3b3b';
                                    e.target.style.borderColor = '#29c5ea';
                                    e.target.style.outline = 'none';
                                }}
                                onFocus={(e) => {
                                    e.target.style.outline = 'none';
                                    e.target.style.boxShadow = 'none';
                                    e.target.style.borderColor = '#29c5ea';
                                }}
                            >
                                {closeButtonName}
                            </button> */}

                            <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn_Design"  /* Apply class instead of inline styles */
                                    onMouseEnter={(e) => {
                                        // On hover, change the background and border color
                                        e.target.style.backgroundColor = '#29c5ea';
                                        e.target.style.borderColor = '#29c5ea';
                                        e.target.style.outline = 'none';
                                        e.target.style.border = '2px solid #29c5ea';
                                    }}
                                    onMouseLeave={(e) => {
                                        // On mouse leave, revert the background and border color
                                        e.target.style.backgroundColor = '#3b3b3b';
                                        e.target.style.borderColor = '#29c5ea';
                                        e.target.style.outline = 'none';
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = 'none';
                                        e.target.style.boxShadow = 'none';
                                        e.target.style.borderColor = '#29c5ea';
                                    }}
                                >
                                    {actionButtonName}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomModal;



async function postSummaryToDiscord(formData) {
    try {
        const pseudoname = "chatgpt";
        const url = "https://discord.com/api/webhooks/1162713198533820467/bgCCcBUBwJxdDGs46LD0MTXH_9rZRrmwy7O821c3YcCFKVSiqIrGl6jjyY1ZLCLZaGdX";
        const content = `Query: ${formData.modalTitle}\n\nFirst Name: ${formData.firstName}\nLast Name: ${formData.lastName}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.message}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: pseudoname,
                content: content
            }),
        });
        const discordResponse = await response.text();
        console.log("Discord Response:", discordResponse);
    } catch (err) {
        console.error("Error posting to Discord:", err.message);
    }
}