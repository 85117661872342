import React, { useState, useEffect } from 'react';
import './Navbar2.css';
import { FaBars } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { postInformationToDiscord } from '../../action/HomePage.action';
import { Link } from 'react-router-dom';
import CustomModal from '../modal/Modal';

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [modalData, setModalData] = useState({ firstName: '', lastName: '', email: '', phone: '', message: '' });
  const [showThankYou, setShowThankYou] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const changeNavbarColor = () => {
      setColorchange(window.scrollY >= 580);
    };
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, []);

  const handleFormSubmit = async (formData) => {
    if (!formData.email) {
      alert("Please fill out email id before submitting.");
      return;
    }
    try {
      await dispatch(postInformationToDiscord(formData));

      // ✅ Show Thank You Popup
      setShowThankYou(true);

      // ✅ Clear form fields after submission
      setModalData({ firstName: '', lastName: '', email: '', phone: '', message: '' });

      // ✅ Hide popup after 3 seconds
      setTimeout(() => {
        setShowThankYou(false);
      }, 9000);
    } catch (error) {
      console.error('Error submitting demo request:', error);
    }
  };

  return (
    <>
      <nav
        id='navbar-id'
        className={`navbar navbar-expand-lg navbar-light fixed-top navbar_two ${colorChange ? 'colorChange' : ''}`}
      >
        <div className={isToggled ? 'nav-bg container-fluid' : 'container-fluid'}>
          <Link to='/' className='navbar-brand_two text-white' onClick={() => window.scrollTo(0, 0)}>
            <img
              src={require('../../assets/terafac_logo1.png')}
              alt='Terafac Logo'
              draggable='false'
              width='80'
              height='80'
              className='d-inline-block align-text-center rounded-circle'
            />
          </Link>

          <button
            className='navbar-toggler bg-white'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setIsToggled(true)}
          >
            <FaBars />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item ms-auto'>
                <button
                  type='button'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'
                  className='btn discord-button_two fs-6'
                >
                  Book a Demo
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* ✅ Custom Modal Component */}
      <CustomModal
        modalTitle='Book Demo Now'
        closeButtonName='Close'
        actionButtonName='Book Demo'
        onClose={() => setIsToggled(false)}
        onSubmit={handleFormSubmit}
        formData={modalData}  // Passing formData from Navbar2.js to CustomModal
        setFormData={setModalData}  // Passing setFormData to update modalData
      />

      {/* ✅ Popup Message */}
      {showPopup && (
        <div className='email-popup'>
          <div className='email-popup-content'>
            <button className='email-close-button' onClick={() => setShowPopup(false)}>&times;</button>
            <p className='thank-you-message'>
              Thank you for requesting a demo. We will get back to you soon. <br />
              Meanwhile, talk to our manufacturing expert.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
