import React from 'react'
import ReactPlayer from 'react-player/lazy'

// Lazy load the YouTube player

const YoutubePlayer = () => {
    return (
        <ReactPlayer url='https://www.youtube.com/watch?v=yv2qeVznGlo' />
    )
}

export default YoutubePlayer;