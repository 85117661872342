import React, { useState } from "react";
import "./ContactPage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  postInformationToDiscord,
  saveEmailId,
} from "../../action/HomePage.action";

const ContactPage = () => {
  // States for form data and validation errors
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false); // Popup visibility
  const savedEmail = useSelector((state) => state.home.email);
  const dispatch = useDispatch();

  // Handling form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  // Form validation logic (same as ContactUsDesktop)
  const validateForm = () => {
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Please enter your name.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email.";
    } else if (!formData.email.includes("@")) {
      newErrors.email = "Email must include '@'.";
    }

    if (!formData.subject.trim()) {
      newErrors.subject = "Please enter the subject.";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Please enter your message.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handle form submission (Same as ContactUsDesktop)
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop form submission if validation fails
    }

    console.log("Form data:", formData);
    dispatch(saveEmailId(formData.email)); // Dispatch saveEmailId action
    dispatch(postInformationToDiscord(formData)); // Dispatch postInformationToDiscord action

    // Show popup after submitting
    setShowPopup(true);

    // Reset form fields after submission
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <>
      <section id="home-contact" className="home-contact light-background">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row">
            <div className="col-lg-6">
              <div className="content" data-aos="fade-up" data-aos-delay="200">
                {/* <div className="section-category mb-3">Contact</div> */}
                <h1 className="main-contact-heading fs-xl-heading">
                  Get in touch with us
                </h1>
                <p className="descriptive-sub-heading lead mb-4">
                    Unleash the future of manufacturing with AI-Vision and Robotics
                </p>
                <p className="lead">
                  Step into the future with us as we revolutionize the
                  manufacturing landscape. Whether you're a trailblazing
                  professional eager to make your mark, or a visionary customer
                  ready to elevate your business with state-of-the-art tech,
                  together we’ll shape a new era. It's time to push boundaries,
                  ignite innovation, and redefine manufacturing.
                </p>

                <div className="contact-info mt-5">
                  <div className="info-item d-flex mb-3">
                    {/* <i className="bi bi-envelope-at me-3"></i> */}
                    <span>info@terafac.com</span>
                  </div>

                  <div className="info-item d-flex mb-3">
                    {/* <i className="bi bi-telephone me-3"></i> */}
                    <span>+91 8264445150</span>
                  </div>

                  <div className="info-item d-flex mb-4">
                    {/* <i className="bi bi-geo-alt me-3"></i> */}
                    <span>Chandigarh, India</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="contact-form card"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="card-body p-4 p-lg-5">
                  <form onSubmit={handleSubmit} className="php-email-form">
                    <div className="contact-input-fields row gy-4">
                      <div className="col-12">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Your Name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                        {errors.name && (
                          <p className="error-message">{errors.name}</p>
                        )}
                      </div>

                      <div className="col-12">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Your Email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <p className="error-message">{errors.email}</p>
                        )}
                      </div>

                      <div className="col-12">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Subject"
                          value={formData.subject}
                          onChange={handleInputChange}
                        />
                        {errors.subject && (
                          <p className="error-message">{errors.subject}</p>
                        )}
                      </div>

                      <div className="col-12">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="6"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleInputChange}
                        ></textarea>
                        {errors.message && (
                          <p className="error-message">{errors.message}</p>
                        )}
                      </div>

                      <div className="col-12 text-center">
                        <button type="submit" className="btn btn-submit w-100">
                          Submit Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="map-div">
        <div className="container">
          <div className="col-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13715.482640096103!2d76.7577135!3d30.7504708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed4300000001%3A0xb6fdf9387593a1e8!2sTerafac%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1714364736751!5m2!1sen!2sin"
              frameBorder="0"
              style={{ border: 0, width: "100%", height: "410px" }}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* ✅ Thank-You Popup */}
      {showPopup && (
        <div className="email-popup">
          <div className="email-popup-content">
            <button
              className="email-close-button"
              onClick={() => setShowPopup(false)}
            >
              &times;
            </button>
            <p className="thank-you-message text-center">
              Thank you for sharing your details! We will get back to you soon.
              <br />
              Meanwhile, talk to our manufacturing expert.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactPage;
