import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import BlogPost from "./components/blog/blog"; // Blog list component
import BlogDetail from "./components/blog/blogdetail"; // Blog detail component
import ManufacturinPage from "./pages/ManufacturinPage"; // Importing Smart Manufacturing Page
import LabForCollege from "./pages/LabForCollege";
import LearningPage from "./pages/LearningPage";
import ContactPage from "./pages/ContactPage";
import TrainingPage from "./pages/TrainingPage";

function App() {
  useEffect(() => {
    let inactivityTime = 1 * 60 * 1000;  // 1 minute in milliseconds
    let timeout;

    // Function to reset the inactivity timer
    function resetTimer() {
      console.log("resetTimer called"); // Log when the function is called
      clearTimeout(timeout); // Clear previous timeout

      timeout = setTimeout(() => {
        console.log("Page is reloaded due to inactivity");
        window.location.reload(); // Reload the page after 1 minute of inactivity
      }, inactivityTime);
    }

    // Set up event listeners for user activity
    window.onload = () => {
      console.log("Window loaded");
      resetTimer();
    };

    window.onmousemove = () => {
      console.log("Mouse moved");
      resetTimer();
    };

    window.onkeydown = () => {
      console.log("Key pressed");
      resetTimer();
    };

    window.ontouchstart = () => {
      console.log("Touch event detected");
      resetTimer();
    };

    // Cleanup event listeners when the component unmounts
    return () => {
      console.log("Cleaning up event listeners");
      window.onmousemove = null;
      window.onkeydown = null;
      window.ontouchstart = null;
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            {/* Home Page */}
            <Route path="/" element={<HomePage />} />

            {/* About Page */}
            <Route path="/about-us" element={<AboutPage />} />

            {/* Blog List Page */}
            <Route path="/blog-post" element={<BlogPost />} />

            {/* Blog Detail Page */}
            <Route path="/blog/:blogId" element={<BlogDetail />} />
            <Route path="/tag/:blogId" element={<BlogDetail />} />

            {/* Smart Manufacturing Page */}
            <Route path="/smart-manufacturing" element={<ManufacturinPage />} />

            {/*  Lab for College / University Page */}
            <Route path="/lab-for-college" element={<LabForCollege />} />

            {/*  Lab for 6th-12th Grade Page */}
            <Route path="/lab-for-school" element={<LearningPage />} />

            {/*    Contact Us Page Route */}
            <Route path="/contact" element={<ContactPage />} />

            <Route path='/trainingworkshop' element={<TrainingPage />} />

            {/* Redirect any unknown routes to Home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
