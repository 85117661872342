import React from 'react'
import { useMediaQuery } from "react-responsive";
import './DemoSection.css'
import YoutubePlayer from '../Youtube/YoutubePlayer'


const DemoSection = () => {

  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <section id="describe" className="describe">
      <div className="container">
        <div className="center-box">
          
          <p className="description">
            <span className="brand-name" style={{ fontFamily: 'Audiowide, sans-serif', fontSize: '2.5rem', fontWeight: 'bold', color: '#00061C'}}>
              TERAFAC
            </span>{`${isMobile ? "\n": ' '}is bringing AI and automation to the world of manufacturing. We’ll enable factories of the future by giving intelligence to shop floor machines, starting with welding robots.`}
          </p>
        </div>
      </div>
    </section>
  );
};

export default DemoSection
