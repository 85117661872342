import React, { useState, useEffect } from "react";
import Navbar2 from "../components/Navbar2/Navbar2";
import Footer from "../components/Footer/Footer";
import HomeContact from "../components/ContactUs/HomeContact";
import backgroundImage from "../assets/Background_terafac1.png";

const HomePage = () => {
  return (
    <div>
      <Navbar2 />
      <div className="contact-us-page">
        <div
          className="contact-page d-flex flex-column justify-content-center"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h1 className="lets-connect">Let's Connect</h1>
        </div>
        <div className="home-contact-space">
          <HomeContact />
        </div>
      </div>
      <Footer id="Footer-section" />
    </div>
  );
};

export default HomePage;
