import React from 'react'
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { FaBeer } from 'react-icons/fa'
import './carousel.css'
import carouselPic2 from '../../assets/carousel-pic2.png'
import carouselPic3 from '../../assets/carousel-pic3.jpeg'
import carouselPic4 from '../../assets/carousel-pic4.jpeg'
import carouselPic5 from '../../assets/carousel-pic5.webp'
import carouselPic6 from '../../assets/carousel-pic6.jpeg'
import carouselPic10 from '../../assets/carousel-pic10.png'
import carouselPic11 from '../../assets/carousel-pic-11.png'
import carouselPic12 from '../../assets/carousel-pic-12.png'
import TruncatedText from '../TextLimiter';

const MyCarousel = () => {
  const navigate = useNavigate();

  // Function to limit text length
  const limitText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...'; // Truncate and add '...'
    }
    return text; // Return as-is if within limit
  };

  const handleReadMore = (id) => {
    console.log(`Navigating to Blog ID: ${id}`);
    navigate(`/blog/${id}`); // Navigate to the BlogDetail page
  };

  const ourprograms = [
    {
      id: "skills-gap",
      image: carouselPic11,
      title: 'Industry 4.0 revolution and skill Gap',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Intermediate',
      enter: 'Enter',
      package: '',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:'This is the age of the 4th Industrial revolution. This means industrial processes are upgraded to work at a much faster pace. Tasks are being automated and much of the data is digitized. The world is going through a tremendous change that will completely change the way things work in industries within a few years',
      job_type: 'On-site',
      banner_image:
        'https://www.websoptimization.com/external/upload/framework-banner/desktop/hire-react-native-mobile-app-programmer-india.jpg',
      btn: 'Read More'
    },
    {
      id: "industrial-automation",
      image: carouselPic10,
      title: 'Augmented Reality on Industrial Machine',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Intermediate',
      enter: 'Enter',
      package: ' ',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        'Augmented Reality enhances an existing physical environment ...',
      job_type: 'Full time',
      banner_image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFnall4lRPrUiMchfInSQf16_dCRTeGcgyLnseouBUcDEDXLqK1j07iqJESxC2fxfNlck&usqp=CAU',
      btn: 'Read More'
    },
    {
      id: "fourth-industrial-revolution",
      image: carouselPic12,
      title: 'Virtual reality, Augmented reality, Mixed reality',
      companies_list: [
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/google.png',
        'https://repetition-images.s3.ap-south-1.amazonaws.com/infosys.png',
        'https://s3.us-west-1.amazonaws.com/assets.terafac.live/images/meesho-preview.png'
      ],
      company: 'The Startup Live',
      address: 'Intermediate',
      enter: 'Coming Soon',
      package: '',
      openings: '',
      qualification: ' ',
      experience: '  ',
      description:
        'Technological advancements over the years have powered the different stages of the industrial revolution. The 1st industrial revolution saw the usage of steam power that increased productivity and the 2nd industrial revolution employed electricity for faster production at lower cost',
      job_type: 'On-site',
      btn: 'Read More'
    },
  ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }

  return (
    <div className='my-own-custom-container'>
      <div className='' data-aos='fade-up'>
        <h1 className='main-carousel-heading fs-xl-heading'>
          Blogs<span style={{ color: "#29c5ea" }}>!</span>
        </h1>
      </div>
      <div className='container-fluid'>
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition='all .5'
          transitionDuration={500}
          containerClass='carousel-container'
          itemClass='carousel-item-padding-10-px'
          showDots={false}
          arrows={true}
        >
          {ourprograms.map((item) => (
            <div
              key={item.id}
              className="card-bg-remove"
              onClick={() => handleReadMore(item.id)} // Make the card clickable
              style={{
                cursor: "pointer",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Card
                style={{
                  width: "20rem",
                  height: 420,
                  backgroundColor: "rgba(41, 197, 234, 0.05)",
                  margin: "10px",
                }}
                className="rounded-img"
              >
                <div className="card-img-wrapper">
                  <Card.Img
                    variant="top"
                    src={item.image}
                    style={{
                      height: 230,
                      width: "100%",
                      objectFit: "cover",
                    }}
                    className="rounded-img"
                  />
                </div>
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <h5
                    className="fs-xl-para"
                    style={{
                      textAlign: "center",
                      color: "#00061C",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif", // Apply Poppins to the title
                    }}
                  >
                    {item.title}
                  </h5>
                  <p
                    className="truncated-text"
                    style={{
                      fontFamily: "Nunito, sans-serif", // Apply Nunito to the description
                      color: "#333", // Optional: Set a color for the description text
                      textAlign: "center"
                    }}
                  >
                    {item.description}
                  </p>
                  <div style={{ textAlign: "center", marginTop: "auto" }}>
                    <button className="read-more-btn" onClick={() => handleReadMore(item.id)}>
                      {item.btn}
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default MyCarousel;
