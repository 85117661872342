import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import { Icon } from "@iconify/react";
import "./mobilefeature.css";

const featureIcons = [
  "mdi:account-cash-outline",
  "mdi:cog-sync",
  "mdi:chart-line",
  "mdi:check-decagram",
];
const features = [
  {
    imgSrc: require("../../assets/features-icon4.png"),
    text: "Low initial investment, ROI within 2 years.",
    offset: false,
  },
  {
    imgSrc: require("../../assets/features-icon3.png"),
    text: "Easy setup, operator-friendly interface, no expert required.",
    offset: true,
  },
  {
    imgSrc: require("../../assets/features-icon2.png"),
    text: "3x productivity, reduce wastage, improve quality.",
    offset: false,
  },
  {
    imgSrc: require("../../assets/features-icon1.png"),
    text: "Centralized data for better decision-making.",
    offset: true,
  },
];

const MobileFeature = () => {
  const mobile = useMediaQuery({ query: "(max-width: 640px)" });
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref} className="">
      {/* <motion.h1
        className="did_you_know fs-xl-heading"
        style={{
          textAlign: "center",
          fontFamily: "poppins",
          margin: 0,
          padding: "60px 0 1px 0",
          backgroundColor: "white",
          textEmphasisColor: "#00061C",
        }}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 1 }}
      >
        Why choose <span style={{ fontFamily: "audiowide" }}>TERA</span>weld{" "}
        <span style={{ color: "orange" }}>?</span>
      </motion.h1> */}
      <div className="df-terafac-live df-feature-column d-flex flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row">
        <motion.div
          className="df-feature-first-column col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5"
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <div className="first-pic-div"></div>
          <div className="second-pic-div" style={{ position: "relative" }}>
            <img
              className="feature-first-pic"
              style={{
                position: "",
                top: 0,
                left: "0%",
                transform: "translateX(-79%)",
              }}
              src={require("../../assets/abb-robot.jpeg")}
              alt=""
            />
            <img
              className="feature-second-pic"
              style={{
                position: "absolute",
                top: 0,
                left: "33%",
                transform: "translateX(-40%)",
              }}
              src={require("../../assets/pnc.jpeg")}
              alt=""
            />
            <img
              className="feature-third-pic"
              style={{
                position: "absolute",
                bottom: 0,
                left: "99%",
                transform: "translateX(-30%)",
              }}
              src={require("../../assets/operator.jpeg")}
              alt=""
            />
          </div>
        </motion.div>
        <div className="feature-second-column col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`col-10 features-div ${
                feature.offset ? "offset-2 ms-4" : "ms-0"
              }`}
            >
              <div className="icon-container">
                <Icon
                  icon={featureIcons[index]} // Use the icon from the array
                  style={{
                    fontSize: "35px",
                    width: "25px",
                    height: "25px",
                    color: "white",
                  }}
                />
              </div>
              <h2>{feature.text}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileFeature;
